import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class SettingsService {
  readonly availableLanguages = ['en', 'de'];

  constructor(
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.onLangChange.subscribe(this.storeLanguage);

    const language = this.loadLanguage() ?? translate.getBrowserLang() ?? 'en';
    this.setLanguage(language);

  }

  get language() {
    return this.translate.currentLang;
  }


  setLanguage(language: string) {
    this.translate.use(language);
  }


  private storeLanguage(event: LangChangeEvent) {
    localStorage.setItem('lang', event.lang);
  }

  private loadLanguage() {
    return localStorage.getItem('lang');
  }
}
