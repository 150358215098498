<div class="container">
    <!-- <nav aria-label="breadcrumb">
    <ol class="breadcrumb px-1">
      <a class="breadcrumb-item"
        href="https://www.siemens.com/global/en/products/buildings/partners/partner-network.html">Home</a>
      <a class="breadcrumb-item active" routerLink="/registration">Registration</a>
    </ol>
  </nav> -->

    <div class="container-fluid">
        <div style="margin-top: 2rem; margin-bottom: 2rem">
            <si-breadcrumb ariaLabel="root as text breadcrumbs" [items]="[
                    { title: 'Home', href: 'https://www.siemens.com/global/en/products/buildings/partners/partner-network.html' },
                    { title: 'Registration', link: ['/registration'] }
                ]" [showRootAsText]="true"></si-breadcrumb>
        </div>

        <div class="row" *ngIf="showSuccessMessage">
            <div class="col-12">
                <p>
                    Registration request submitted. Thank you for your registration. Your request has been sent to the
                    responsible
                    administrator. The verification process usually takes about 48 hours. You will be contacted via
                    e-mail upon completion.
                </p>
                <div class="col-12 text-center">
                    <i class="success-icon element-checked text-primary"></i>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!showSuccessMessage">
            <!-- <si-card heading="Partner Portal Registration" [actionBarViewType]="'expanded'"> -->

            <!-- <div class="card-body" body> -->

            <div class="col-12">
                <h6 *ngIf="!siteParam">Partner Portal Registration</h6>
                <h6 *ngIf="siteParam === 'oem'">OEM Portal Registration</h6>
                <h6 *ngIf="siteParam === 'cccommunity'">Desigo CC Ecosystem Registration</h6>
            </div>
            <div class="col-12">
                <p *ngIf="!siteParam">
                    The Partner Portal is your prime source for information and hosts an extensive repository of tools,
                    libraries, trainings
                    and support. The resources are available exclusively to certified Solution Partners of Siemens
                    Building Technologies. In
                    order to get access to the portal, you have to register first, using this form:
                </p>
                <p *ngIf="siteParam === 'oem'">
                    The OEM Partner Portal is your prime source for information and hosts an extensive repository of
                    tools, libraries,
                    trainings and support. The resources are available exclusively to certified OEM Partners of Siemens
                    Building
                    Technologies. In order to get access to the portal, you have to register first, using this form:
                </p>
                <p *ngIf="siteParam === 'cccommunity'">
                    Desigo CC Ecosystem has been created to enable the dynamic and innovative development of building
                    management, to gather
                    information and to exchange ideas, products and services. As a community member you will profit from
                    a number of
                    benefits when working with Desigo CC, including learning, exchanging information and collaborating.
                </p>
            </div>
            <!-- <ng-container> -->
            <form class="d-flex h-100 register-form" [formGroup]="form" (ngSubmit)="submit()">
                <si-form-container #formContainer [form]="form" [errorCodeTranslateKeyMap]="errorCodeTranslateKeyMap"
                    [controlNameTranslateKeyMap]="controlNameTranslateKeyMap">
                    <div si-form-container-content>
                        <div class="row">
                            <div class="col-6">
                                <si-form-item label="First Name" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <input type="text" id="firstName" class="form-control"
                                            formControlName="firstName" siFormControl />

                                        <i class="invalid-icon element-cancel-filled"></i>
                                        <div class="invalid-feedback">
                                            <span
                                                *ngFor="let error of formContainer.getValidationErrors('firstName'); trackBy: trackByIndex">
                                                {{
                                                error.errorCodeTranslationKey
                                                ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                : error.errorCode
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </si-form-item>

                                <si-form-item label="Last Name" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <input type="text" id="lastName" class="form-control" formControlName="lastName"
                                            siFormControl />
                                        <i class="invalid-icon element-cancel-filled"></i>
                                        <div class="invalid-feedback">
                                            <span
                                                *ngFor="let error of formContainer.getValidationErrors('lastName'); trackBy: trackByIndex">
                                                {{
                                                error.errorCodeTranslationKey
                                                ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                : error.errorCode
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </si-form-item>

                                <si-form-item label="Company" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <input type="text" id="company" class="form-control" formControlName="company"
                                            siFormControl />
                                        <i class="invalid-icon element-cancel-filled"></i>
                                        <div class="invalid-feedback">
                                            <span
                                                *ngFor="let error of formContainer.getValidationErrors('company'); trackBy: trackByIndex">
                                                {{
                                                error.errorCodeTranslationKey
                                                ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                : error.errorCode
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </si-form-item>

                                <si-form-item label="Department">
                                    <input type="text" id="department" class="w-100 form-control"
                                        formControlName="department" siFormControl />
                                </si-form-item>

                                <si-form-item label="Street">
                                    <input type="text" class="w-100 form-control" id="street" formControlName="street"
                                        siFormControl />
                                </si-form-item>

                                <si-form-item label="Zip">
                                    <input type="text" class="w-100 form-control" id="zip" formControlName="zip"
                                        siFormControl />
                                </si-form-item>

                                <si-form-item label="City">
                                    <input type="text" class="w-100 form-control" id="city" formControlName="city"
                                        siFormControl />
                                </si-form-item>

                                <si-form-item label="Phone" [required]="false">
                                    <div class="form-control-has-icon w-100">
                                        <si-phone-number-input class="form-control" formControlName="phone"
                                            country="'CH'" placeholderForSearch="FORM.SEARCH"
                                            selectCountryAriaLabel="FORM.SELECT_COUNTRY"
                                            [supportedCountries]="countryKeys"
                                            (valueChange)="handlePhoneNumberChange($event)"></si-phone-number-input>
                                        <i class="invalid-icon element-cancel-filled mt-1"></i>
                                        <div class="invalid-feedback">
                                            <span
                                                *ngFor="let error of formContainer.getValidationErrors('phone'); trackBy: trackByIndex">
                                                {{
                                                error.errorCodeTranslationKey
                                                ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                : error.errorCode
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </si-form-item>

                                <si-form-item label="Country" [required]="true">
                                    <si-select class="form-control" formControlName="country" id="country"
                                        [ariaLabel]="'Please choose your country or region' | translate"
                                        [options]="countriesOptions"></si-select>
                                    <!-- <i class="invalid-icon element-cancel-filled"></i> -->
                                    <div class="invalid-feedback">
                                        <span
                                            *ngFor="let error of formContainer.getValidationErrors('country'); trackBy: trackByIndex">
                                            {{
                                            error.errorCodeTranslationKey
                                            ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                            : error.errorCode
                                            }}
                                        </span>
                                    </div>
                                    <!-- <si-dropdown
                        formControlName="country"
                        inputId="country"
                        [dropdownOptions]="countriesOptions"
                        [valueProvider]="objectValueProvider"
                        [keyProvider]="objectKeyProvider"
                      ></si-dropdown> -->
                                </si-form-item>
                            </div>

                            <div class="col-6 g-3">
                                <si-form-item label="Email" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <input id="email" formControlName="email" class="form-control" type="email"
                                            siFormControl />
                                        <i class="invalid-icon element-cancel-filled"></i>
                                        <div class="invalid-feedback">
                                            <span
                                                *ngFor="let error of formContainer.getValidationErrors('email'); trackBy: trackByIndex">
                                                {{
                                                error.errorCodeTranslationKey
                                                ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                : error.errorCode
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </si-form-item>

                                <si-form-item label="Password" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <si-password-strength>
                                            <!-- <div class="position-relative"> -->

                                            <input #password id="password" type="password" name="password"
                                                aria-label="password" [type]="showPassword ? 'text' : 'password'"
                                                class="form-control" formControlName="password" siFormControl ngModel
                                                [siPasswordStrength]="{
                                                    minLength: 8,
                                                    uppercase: true,
                                                    lowercase: true,
                                                    digits: true,
                                                    special: true
                                                }" />
                                            <i class="icon element-show position-absolute top-0 end-0 mt-2 me-4"
                                                role="button" tabindex="0"
                                                [attr.aria-label]="showPassword ? 'hide password' : 'show password'"
                                                [class.text-primary]="showPassword"
                                                (click)="showPassword = !showPassword"
                                                (keydown.enter)="showPassword = !showPassword"></i>

                                            <!-- </div> -->

                                            <div class="invalid-feedback">
                                                <span *ngFor="
                                                        let error of formContainer.getValidationErrors('password');
                                                        trackBy: trackByIndex
                                                    ">
                                                    {{
                                                    error.errorCodeTranslationKey
                                                    ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                    : error.errorCode
                                                    }}
                                                </span>
                                            </div>
                                        </si-password-strength>
                                    </div>
                                </si-form-item>

                                <si-form-item label="Confirm Password" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <si-password-strength>
                                            <!-- <div class="position-relative"> -->
                                            <input #retypePassword id="retypePassword" type="password"
                                                name="retypePassword" aria-label="retypePassword" class="form-control"
                                                formControlName="retypePassword" siFormControl ngModel
                                                [type]="showRetypePassword ? 'text' : 'password'" />
                                            <i class="icon element-show position-absolute top-0 end-0 mt-2 me-4"
                                                role="button" tabindex="0"
                                                [attr.aria-label]="showRetypePassword ? 'hide password' : 'show password'"
                                                [class.text-primary]="showRetypePassword"
                                                (click)="showRetypePassword = !showRetypePassword"
                                                (keydown.enter)="showRetypePassword = !showRetypePassword"></i>
                                            <!-- </div> -->
                                            <div class="invalid-feedback">
                                                <span *ngFor="
                                                        let error of formContainer.getValidationErrors('retypePassword');
                                                        trackBy: trackByIndex
                                                    ">
                                                    {{
                                                    error.errorCodeTranslationKey
                                                    ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                    : error.errorCode
                                                    }}
                                                </span>
                                            </div>
                                        </si-password-strength>
                                    </div>
                                </si-form-item>

                                <si-form-item>
                                    <p>
                                        Passwords cannot be less than 8 characters long and must
                                        contain at least one lower and upper case letter, number
                                        and special character except . ' " & <> / | =
                                    </p>
                                </si-form-item>
                                <si-form-item label="Check to proceed" [required]="true">
                                    <div class="form-control-has-icon w-100">
                                        <re-captcha formControlName="recaptcha"
                                            class="form-control captcha-form"></re-captcha>
                                        <!-- <re-captcha  name="recaptcha" required siteKey="6LcdNKcoAAAAAMpcgAeBQKfQld8y0LBU5tvxG_Yu"></re-captcha> -->
                                        <!-- <i class="invalid-icon element-cancel-filled"></i> -->
                                        <div class="invalid-feedback">
                                            <span
                                                *ngFor="let error of formContainer.getValidationErrors('recaptcha'); trackBy: trackByIndex">
                                                {{
                                                error.errorCodeTranslationKey
                                                ? (error.errorCodeTranslationKey | translate: error.errorParams)
                                                : error.errorCode
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </si-form-item>

                                <div class="row" *ngIf="siteParam !== 'cccommunity'">
                                    <si-form-item>
                                        <div class="col-6 form-control-has-icon w-100" formGroupName="portalSection">
                                            <hr class="hr" />

                                            <p class="mb-3">I request access for the following portal sections</p>

                                            <div class="form-check" *ngIf="!siteParam">
                                                <input id="buildingAutomation" class="form-check-input"
                                                    formControlName="buildingAutomation" type="checkbox" />
                                                <label for="buildingAutomation" class="form-check-label"> Building
                                                    Automation </label>
                                            </div>

                                            <div class="form-check" *ngIf="!siteParam">
                                                <input id="fireSafety" class="form-check-input"
                                                    formControlName="fireSafety" type="checkbox" />
                                                <label for="fireSafety" class="form-check-label"> Fire Safety </label>
                                            </div>

                                            <div class="form-check" *ngIf="siteParam === 'oem'">
                                                <input id="climatixOem" class="form-check-input"
                                                    formControlName="climatixOem" type="checkbox" />
                                                <label for="climatixOem" class="form-check-label"> Climatix OEM </label>
                                            </div>

                                            <div class="form-check" *ngIf="siteParam === 'oem'">
                                                <input id="combustion" class="form-check-input"
                                                    formControlName="combustion" type="checkbox" />
                                                <label for="combustion" class="form-check-label"> Combustion </label>
                                            </div>

                                            <!-- <div class="invalid-feedback">
                      <span *ngFor="
                                  let error of formContainer.getValidationErrors('portalSection');
                                  trackBy: trackByIndex
                                ">
                        {{
                        error.errorCodeTranslationKey
                        ? (error.errorCodeTranslationKey | translate : error.errorParams)
                        : error.errorCode
                        }}
                      </span>
                    </div> -->
                                        </div>
                                    </si-form-item>
                                </div>
                            </div>
                        </div>
                        <!-- end of row div -->
                    </div>

                    <div si-form-container-buttons>
                        <button class="btn btn-primary me-6" type="submit">Submit</button>

                        <button class="btn btn-secondary me-6" type="reset">Clear</button>
                    </div>
                </si-form-container>
            </form>
            <!-- </ng-container> -->
            <!-- </div> -->
            <!-- </si-card> -->
        </div>
    </div>
</div>