import { Injectable } from '@angular/core';
import { Link, SiToastNotificationService, ToastStateName } from '@simpl/element-ng';


@Injectable({ providedIn: 'root' })
export class ToastrService {
  readonly availableLanguages = ['en', 'de'];

  constructor(private toastService: SiToastNotificationService) { }


  showToast(
    state: ToastStateName,
    title: string,
    message: string,
    disableAutoClose?: boolean,
    disableManualClose?: boolean,
    timeout?: number,
    action?: Link
  ): void {
    this.toastService.showToastNotification({
      state,
      title,
      message,
      disableAutoClose,
      disableManualClose,
      timeout,
      action
    });
  }
}
