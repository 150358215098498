import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from '../services/toastr.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastrService) { }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            this.toastService.showToast("warning", error.error.message, error.statusText);
            break;
          case 403:
            this.toastService.showToast("warning", "Solution code is not correct", "");
            break;
          case 409:
            this.toastService.showToast(
              'danger',
              'Error',
              'This login already exists. Please sign in with Login & Password.',
              undefined,
              undefined,
              5000
            );
            break;
          case 500:
            this.toastService.showToast("danger", error.message, error.statusText);
            break;
          default:
            this.toastService.showToast("danger", "", "Internal error");
            break;
        }

        return throwError(error);
      })
    );
  }
}
