import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DARK } from '../../environments/theme';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  dark = DARK;
  appTitle = '';
  siteParam: any;

  themeToggleQuickAction = {
    title: 'NAV.LANGUAGE',
    icon: 'element-sun',
    action: () => this.toggleTheme()
  };

  titleLink = {
    action: () => this.reloadPage()
  }

  constructor(private route: ActivatedRoute, private router: Router, private _elementRef: ElementRef) {
    
    // settingsService.setLanguage()
    // this.switchLanguage(this.currentLanguage);
    // this.switchTheme(this.currentTheme ?? 'auto');

   
  }

  ngOnInit(): void {

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {

          this.route.queryParamMap.subscribe((p: any) => {

            const params = p.params;
            this.siteParam = [params.SubSite, params.subsite, params.Subsite, params.subSite].find(s => s);

            let headerUrl = 'url(/assets/images/default_header.jpg)'
            this.appTitle = 'Partner Portal';

            if (this.siteParam === 'oem') {
              
              this.appTitle = 'OEM Partner Portal';
              headerUrl = 'url(/assets/images/oem_header.jpg)';

            } else if (this.siteParam === 'cccommunity') {
              
              this.appTitle = 'Desigo CC Ecosystem';
              headerUrl = 'url(/assets/images/desigo_header.jpg)';
            }


            const elements = this._elementRef.nativeElement.querySelectorAll('.collapse.navbar-collapse');
            const element = elements[0];
            element.style["background-image"] = headerUrl;
            element.style["background-repeat"] = 'no-repeat';
            element.style["background-position"] = 'center';

          });


        }
      })
    
  }

  private reloadPage() {
    console.log("Reloading page");
    window.location.reload();
  }

  private toggleTheme(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // this.themeService.resolvedColorScheme === 'light'
    //   ? this.switchTheme('dark')
    //   : this.switchTheme('light');
  }
}


