import { A11yModule } from "@angular/cdk/a11y";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SI_COPYRIGHT_DETAILS, SiBreadcrumbModule, SiCardModule, SiDropdownModule, SiFooterModule, SiFormModule, SiLanguageSwitcherModule, SiNavbarModule, SiPasswordStrengthModule, SiSelectModule } from "@simpl/element-ng";
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { SiPhoneNumberInputModule } from '@simpl/element-ng/phone-number';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { RegistrationComponent } from "./registration/registration.component";

import { environment } from '../environments/environment';
import { NgxSpinnerModule } from "ngx-spinner";
import { ErrorInterceptor } from "./shared/interceptors/error.interceptor";

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    A11yModule,
    AppRoutingModule,
    SiFooterModule,
    SiBreadcrumbModule,
    ReactiveFormsModule,
    SiFormModule,
    SiDropdownModule,
    SiNavbarModule,
    SiCardModule,
    HttpClientModule,
    SiTranslateNgxTModule,
    SiPasswordStrengthModule,
    SiPhoneNumberInputModule,
    SiSelectModule,
    SiBreadcrumbModule,
    SiLanguageSwitcherModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule
    
  ],
  providers: [
    {
      provide: SI_COPYRIGHT_DETAILS,
      useValue: {
        startYear: 2023
      }
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey
      } as RecaptchaSettings
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }

  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
