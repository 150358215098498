<!-- <header>
  <nav #siHeader="siHeader" [cdkTrapFocus]="siHeader.navbarExpandedOrDropdownOpen" siHeader
    class="si-header si-header-internet fixed-top" [class.bg-dark]="dark" [class.bg-light]="!dark">
    <div siHeaderContainer class="container">
      <a routerLink="/" class="si-header-brand" aria-label="Siemens logo">
        <si-logo></si-logo>
      </a>
      <button class="si-header-toggler" type="button" siHeaderToggler aria-label="Toggle navigation">
        <span class="siemens-menu"></span>
      </button>
    </div>
  </nav>
</header> -->
<div class="container">
  <si-navbar-primary 
  [appTitle]="appTitle"
  [home]="titleLink"
  navAriaLabel="Primary"
  [account]=""
  >
  

  <si-navbar-item quickAction [item]="{ title: 'Language', icon: 'element-global', customContent: true,
  iconOnly: false }"> 
    <si-language-switcher translationKey="LANGUAGE" [availableLanguages]="['en', 'de', 'cn']">
    </si-language-switcher>
  </si-navbar-item>
  
  </si-navbar-primary>
</div>


