<si-footer
  copyright="Siemens 2016-2023"
  [links]="[
    {
      title: 'Corporate Information',
      href: 'http://www.siemens.com/corporate-information'
    },
    {
      title: 'Privacy Notice',
      href: 'http://www.siemens.com/privacy-notice'
    },
    {
      title: 'Terms of Use',
      href: 'http://www.siemens.com/terms-of-use'
    },
    {
      title: 'Cookie Notice',
      href: 'http://www.siemens.com/cookie-notice'
    },
    {
      title: 'Digital ID',
      href: 'http://www.siemens.com/digital-id'
    }
  ]"
></si-footer>