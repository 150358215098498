import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const password: string = control.get('password')?.value;
  const retypePassword: string = control.get('retypePassword')?.value;

  if (password !== retypePassword) {
    control.get('retypePassword')?.setErrors({ noMatch: true });
    return ({ noMatch: true });
  }

  return null;
}
