import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SettingsService } from './shared/services/settings.service';

@Component({
  selector: '<app-root>',
  templateUrl: './app.component.html',
  animations: []
})
export class AppComponent {

  selectedValue = this.settingsService.language;

  
  constructor(private settingsService: SettingsService) {}

  getRouteAnimation(outlet: RouterOutlet) {
    return (outlet.isActivated ? outlet.activatedRoute : null);
  }
}
