import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@simpl/element-ng';
import { PhoneDetails } from '@simpl/element-ng/phone-number';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from '../shared/interfaces/countries';
import { ToastrService } from '../shared/services/toastr.service';
import { atLeastOneCheckValidator } from '../shared/validators/at-least-one-checked.validator';
import { passwordMatchValidator } from '../shared/validators/password.validator';
import { RegistrationService } from './registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, AfterContentChecked {
  form: UntypedFormGroup;
  countriesOptions: { id: string; title: string }[] = [];
  countryMap: any;
  countryKeys: string[] = [];

  showSuccessMessage = false;

  protected showPassword = false;
  protected showRetypePassword = false;

  siteParam: any;

  errorCodeTranslateKeyMap = new Map<string, string>([
    ['required', 'FORM.REQUIRED'],
    ['firstName.required', 'FORM.FIRST_NAME_REQUIRED'],
    ['lastName.required', 'FORM.LAST_NAME_REQUIRED'],
    ['company.required', 'FORM.COMPANY_REQUIRED'],
    ['country.required', 'FORM.COUNTRY_REQUIRED'],
    ['email.required', 'FORM.EMAIL_REQUIRED'],
    ['email.email', 'FORM.EMAIL_EMAIL'],
    ['password.required', 'FORM.PASSWORD_REQUIRED'],
    ['password.siPasswordStrength', 'FORM.PASSWORD_STRENGTH'],
    ['retypePassword.required', 'FORM.RETYPE_PASSWORD_REQUIRED'],
    ['retypePassword.noMatch', 'FORM.RETYPE_PASSWORD_NO_MATCH'],
    ['recaptcha.required', 'FORM.RECAPTCHA_REQUIRED'],
    ['phone.invalidPhoneNumberFormat', 'FORM.INVALID_PHONE'],
    ['termsAccepted.required', 'FORM.ACCEPT_TERMS_REQUIRED'],
    ['minlength', 'FORM.MINLENGTH'],
    ['notEighteen', 'FORM.NOT_EIGHTEEN'],
    ['departureTime', 'FORM.DEPARTURE_AFTER_ARRIVAL'],
    ['birthday.invalid', 'FORM.INVALID_DATE'],
    ['travelDate.invalidStartDateFormat', 'FORM.INVALID_DATE'],
    ['travelDate.invalidEndDateFormat', 'FORM.INVALID_DATE'],
    ['travelDate.endBeforeStart', 'FORM.END_BEFORE_START'],
    ['travelDate.required', 'FORM.TRAVEL_DATE_REQUIRED']
  ]);

  controlNameTranslateKeyMap = new Map<string, string>([
    ['firstName', 'FORM.FIRST_NAME'],
    ['role', 'FORM.ROLE'],
    ['description', 'FORM.DESCRIPTION'],
    ['phoneNumber', 'FORM.PHONE_NUMBER'],
    ['birthday', 'FORM.BIRTHDAY'],
    ['termsAccepted', 'FORM.ACCEPT_TERMS'],
    ['serviceClass', 'FORM.CLASS']
  ]);

  optionsList: SelectOption[] = [
    { id: 'first', icon: 'element-face-happy', color: 'status-success', title: 'First class' },
    { id: 'business', icon: 'element-face-neutral', color: 'status-warning', title: 'Business' },
    { id: 'economy', icon: 'element-face-unhappy', color: 'status-danger', title: 'Economy' }
  ];

  constructor(
    formBuilder: UntypedFormBuilder,
    private registrationService: RegistrationService,
    private translate: TranslateService,
    private toastService: ToastrService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {

    this.form = formBuilder.group(
      {
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        company: [null, Validators.required],
        department: null,
        street: null,
        zip: null,
        city: null,
        phone: null,
        country: [null, Validators.required],
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required]],
        retypePassword: [null, Validators.required],
        portalSection: formBuilder.group(
          {
            buildingAutomation: null,
            fireSafety: null,
            climatixOem: null,
            combustion: null
          }
          // { validators: atLeastOneCheckValidator(this.siteParam) }
        ),
        recaptcha: [null, Validators.required],
        subsite: null
      },
      { validators: passwordMatchValidator }
    );
    
    this.route.queryParamMap.subscribe((p: any) => {
      const params = p.params;
      this.siteParam = [params.SubSite, params.subsite, params.Subsite, params.subSite].find(s => s);
      
      this.form.patchValue({ subsite: this.siteParam });
      this.form.get("portalSection")?.clearValidators();
      this.form.get("portalSection")?.addValidators((atLeastOneCheckValidator(this.siteParam) as ValidatorFn));
    });
    
  }

  ngOnInit(): void {
    this.countriesOptions = countries;
    this.countryMap = new Map(this.countriesOptions.map(element => [element.id, element.title]));
    this.countryKeys = [...this.countryMap.keys()];
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getErrors(controlName: string): ValidationErrors | null | undefined {
    return this.form.get(controlName)?.errors;
  }

  objectValueProvider(object: { value: string; name: string }) {
    return object.name;
  }

  objectKeyProvider(object: { value: string; name: string }) {
    return object.value;
  }

  changePasswordType(input: HTMLInputElement): void {
    const password = 'password';
    const text = 'text';

    if (input.type === password) {
      input.type = text;
    } else {
      input.type = password;
    }
  }

  submit(): void {
    console.log("heyyy");
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.spinner.show();

      const copyForm = {
        ...this.form.getRawValue(),
        country: {
          value: this.form.getRawValue().country.toLowerCase(),
          name: this.countryMap.get(this.form.getRawValue().country)
        }
      };

      this.registrationService.register(copyForm).subscribe({
        next: response => {
          this.spinner.hide();

          this.showSuccessMessage = true;

          this.toastService.showToast(
            'success',
            'Finished',
            'Registration was completed successfully.',
            undefined,
            undefined,
            5000
          );
        },
        error: err => {
          this.spinner.hide();

          //     this.toastService.showToast(
          //       'danger',
          //       'Error',
          //       'An error occured while registering.',
          //       undefined,
          //       undefined,
          //       5000
          //     );
        }
      });
    }
  }

  trackByIndex = (index: number): number => index;

  handlePhoneNumberChange(event: PhoneDetails): PhoneDetails {
    return event;
  }

  get language() {
    return this.translate.currentLang;
  }
}
